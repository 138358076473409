/* CSS Reset & Normalize */
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.5;
  background-color: #f8fafc;
}

#root {
  overflow-x: hidden;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Remove default button styles */
button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

/* Remove default list styles */
ul, ol {
  list-style: none;
}

/* Improve media defaults */
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
  height: auto;
}

/* Remove default table spacing */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Avoid text overflows */
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

/* Mobile-first media queries */
@media screen and (max-width: 600px) {
  html {
    font-size: 14px; /* Slightly smaller base font size for mobile */
  }
  
  /* Prevent pull-to-refresh but keep normal scrolling */
  body {
    overscroll-behavior-y: contain;
  }
} 